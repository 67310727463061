export default {
    props: {
        field: Object
    },
    computed: {
        config() {
            return this.$store.state.configs[this.$store.state.activeConfig]
        }
    },
    mounted() {
        const configField = this.config.find(field => field._id === this.field._id)
        if (configField) this.checked = configField.value
    },
    data() {
        return {
            checked: false,
        }
    },
    methods: {
        check: function() {
            this.checked = true
            this.$emit('change', true)
        },
        uncheck: function() {
            this.checked = false
            this.$emit('change', false)
        }
    }
}